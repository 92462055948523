<template>
  <v-container fluid class="service-detail-height overflow-auto">
    <v-row>
      <v-col cols="6" class="pb-0 pl-4">
        <perfect-scrollbar
          :options="{ suppressScrollX: true }"
          class="scroll"
          style="position: relative"
        >
          <table class="width-100">
            <tr>
              <th colspan="2" class="font-size-18 py-2">Basic Information</th>
            </tr>
            <!--       <pre>{{ detail }}</pre> -->
            <tr>
              <td class="font-size-18 font-weight-500 py-2" width="250">
                Name
              </td>
              <td class="font-weight-600 font-size-18 py-2">
                <span v-if="detail && detail.name">{{ detail.name }}</span>
                <em v-else class="text-muted"> no name </em>
              </td>
            </tr>
            <tr>
              <td class="font-size-18 font-weight-500 py-2" width="250">
                Serial Number
              </td>
              <td class="font-weight-600 font-size-18 py-2">
                <span v-if="detail && detail.serial_no">{{
                  detail.serial_no
                }}</span>
                <em v-else class="text-muted"> no serial number </em>
              </td>
            </tr>
            <tr v-if="detail.product_type == 'goods'">
              <td class="font-size-18 font-weight-500 py-2" width="250">
                Part Number
              </td>
              <td class="font-weight-600 font-size-18 py-2">
                <span v-if="detail && detail.part_number">{{
                  detail.part_number
                }}</span>
                <em v-else class="text-muted"> no part number </em>
              </td>
            </tr>

            <tr v-if="detail.product_type == 'goods'">
              <td class="font-size-18 font-weight-500 py-2" width="250">
                Brand
              </td>
              <td class="font-weight-600 font-size-18 py-2">
                <span v-if="detail && detail.brand">{{ detail.brand }}</span>
                <em v-else class="text-muted"> no brand </em>
              </td>
            </tr>
            <tr>
              <td class="font-size-18 font-weight-500 py-2" width="250">
                Category
              </td>
              <td class="font-weight-600 font-size-18 py-2">
                <span v-if="detail && detail.category">{{
                  detail.category
                }}</span>
                <em v-else class="text-muted"> no category </em>
              </td>
            </tr>

            <tr v-if="false">
              <td class="font-size-18 font-weight-500 py-2" width="250">
                Sellable
              </td>
              <td class="font-weight-600 font-size-18 py-2">
                <template v-if="dataLoading">
                  <v-skeleton-loader
                    class="custom-skeleton width-80"
                    type="text"
                  >
                  </v-skeleton-loader>
                </template>
                <template v-else>
                  {{ detail.is_sellable ? "Yes" : "No" }}
                </template>
              </td>
            </tr>
            <tr v-if="detail.product_type == 'goods'">
              <td class="font-size-18 font-weight-500 py-2" width="250">
                Website URL
              </td>
              <td class="font-weight-600 font-size-18 py-2">
                <template v-if="dataLoading">
                  <v-skeleton-loader
                    class="custom-skeleton width-80"
                    type="text"
                  >
                  </v-skeleton-loader>
                </template>
                <template v-else>
                  <template v-if="detail.website_url">
                    {{ detail.website_url }}
                  </template>
                  <em v-else class="text-muted"> no website url</em>
                </template>
              </td>
            </tr>

            <tr>
              <td class="font-size-18 font-weight-500 py-2 d-flex" width="250">
                Description
              </td>
              <td class="font-weight-600 font-size-18 py-2">
                <span v-if="detail && detail.description">{{
                  detail.description
                }}</span>
                <em v-else class="text-muted"> no description </em>
              </td>
            </tr>

            <template v-if="pType == 'equipment'">
              <tr class="line-height5">
                <td class="font-size-18 font-weight-500 py-2">Batch Number</td>
                <td class="font-weight-600 font-size-18 py-2">
                  <template v-if="detail && detail.batch_number">
                    <span class="text-capitalize">{{
                      detail.batch_number
                    }}</span>
                  </template>
                  <template v-else>
                    <span class="text-muted">no batch number </span></template
                  >
                </td>
              </tr>
              <tr class="line-height5">
                <td class="font-size-18 font-weight-500 py-2">Selling Price</td>
                <td class="font-weight-600 font-size-18 py-2">
                  <template v-if="detail && detail.selling_price">
                    <!-- <span class="text-capitalize">{{ formatMoney(detail.selling_price) }}</span> -->
                    <v-chip outlined text-color="" label small color="blue">
                      {{ formatMoney(detail.selling_price) }}
                    </v-chip>
                  </template>
                  <template v-else>
                    <span class="text-muted"> no Selling Price</span>
                  </template>
                </td>
              </tr>
              <br />
              <tr
                style="
                  border-bottom: 1px solid lightgray !important;
                  padding: 10px;
                "
              ></tr>
              <br />
              <tr>
                <th colspan="2" class="font-size-18 py-2">Dates</th>
              </tr>
              <tr class="">
                <td class="font-size-18 font-weight-500 py-2">
                  Installation Date
                </td>
                <td class="font-weight-600 font-size-18 py-2">
                  <template v-if="detail && detail.installation_date">
                    {{ formatDate(detail.installation_date) }}
                  </template>
                  <template v-else>
                    <em class="text-muted"> no Installation Date </em>
                  </template>
                </td>
              </tr>
              <tr class="">
                <td class="font-size-18 font-weight-500 py-2">
                  Activation Date
                </td>
                <td class="font-weight-600 font-size-18 py-2">
                  <template v-if="detail && detail.activation_date">
                    {{ formatDate(detail.activation_date) }}
                  </template>
                  <template v-else>
                    <em class="text-muted"> no Activation Date </em>
                  </template>
                </td>
              </tr>
              <tr class="">
                <td class="font-size-18 font-weight-500 py-2">End Life</td>
                <td class="font-weight-600 font-size-18 py-2">
                  <template v-if="detail && detail.end_life">
                    {{ formatDate(detail.end_life) }}
                  </template>
                  <template v-else>
                    <em class="text-muted"> no End Life </em>
                  </template>
                </td>
              </tr>
              <br />
              <tr
                style="
                  border-bottom: 1px solid lightgray !important;
                  padding: 10px;
                "
              ></tr>
              <br />
              <tr v-if="allowPurchaseinfo">
                <th colspan="2" class="font-size-18 py-2">Purchase Info</th>
              </tr>
              <!-- <tr class="line-height5">
                  <td class="font-size-18 font-weight-500 py-2">Currency</td>
                  <td class="font-weight-600 font-size-18 py-2">
                    <template
                      v-if="
                        detail && detail.equip_cost_currency && detail.equip_cost_currency != 'null'
                      "
                    >
                      <span class="text-capitalize">{{ detail.equip_cost_currency }}</span>
                    </template>
                    <template v-else> no currency </template>
                  </td>
                </tr> -->
              <tr class="line-height5" v-if="allowPurchaseinfo">
                <td class="font-size-18 font-weight-500 py-2">Cost</td>
                <td class="font-weight-600 font-size-18 py-2">
                  <template
                    v-if="
                      detail && detail.equip_cost && detail.equip_cost != 'null'
                    "
                  >
                    <span class="text-capitalize">
                      <v-chip outlined text-color="" label small color="blue">
                        {{ detail.equip_cost }}
                        {{
                          detail.equip_cost_currency &&
                          detail.equip_cost_currency != "null"
                            ? detail.equip_cost_currency
                            : ""
                        }}
                      </v-chip>
                    </span>
                  </template>
                  <template v-else>
                    <span class="text-muted"> no cost</span>
                  </template>
                </td>
              </tr>
              <tr class="line-height5" v-if="allowPurchaseinfo">
                <td class="font-size-18 font-weight-500 py-2">
                  Purchase Invoice Number
                </td>
                <td class="font-weight-600 font-size-18 py-2">
                  <template
                    v-if="
                      detail &&
                      detail.purchase_invoice_number &&
                      detail.purchase_invoice_number != 'null'
                    "
                  >
                    <span class="text-capitalize">{{
                      detail.purchase_invoice_number
                    }}</span>
                  </template>
                  <template v-else>
                    <span class="text-muted"
                      >no purchase invoice number</span
                    ></template
                  >
                </td>
              </tr>
              <tr v-if="pType == 'equipment' && allowPurchaseinfo">
                <td class="font-size-18 font-weight-500 py-2" width="250">
                  Purchase Date
                </td>
                <td class="font-weight-600 font-size-18 py-2">
                  <template v-if="dataLoading">
                    <v-skeleton-loader
                      class="custom-skeleton width-80"
                      type="text"
                    >
                    </v-skeleton-loader>
                  </template>
                  <template v-else>
                    <template v-if="detail.purchase_date">
                      {{ formatDate(detail.purchase_date) }}
                    </template>
                    <em v-else class="text-muted"> no purchase date</em>
                  </template>
                </td>
              </tr>
              <tr class="line-height5" v-if="false">
                <td class="font-size-18 font-weight-500 py-2">Contract No.</td>
                <td class="font-weight-600 font-size-18 py-2">
                  <template
                    v-if="
                      detail &&
                      detail.contract_number &&
                      detail.contract_number != 'null'
                    "
                  >
                    <span class="text-capitalize">{{
                      detail.contract_number
                    }}</span>
                  </template>
                  <template v-else>
                    <span class="text-muted">no contract no.</span></template
                  >
                </td>
              </tr>
            </template>

            <!-- <tr>
              <template v-if="detail.unit">
                <td class="font-size-18 font-weight-500 py-2" width="250">
                  UOM
                </td>
                <td class="font-weight-600 font-size-18 py-2">
                  <span v-if="detail && detail.unit"> {{ detail.unit }}</span>
                  <em v-else class="text-muted"> no UOM</em>
                </td>
              </template>
            </tr> -->
            <tr v-if="false">
              <td class="font-size-18 font-weight-500 py-2" width="250">
                Manage Stock
              </td>
              <td class="font-weight-600 font-size-18 py-2">
                <template v-if="dataLoading">
                  <v-skeleton-loader
                    class="custom-skeleton width-80"
                    type="text"
                  >
                  </v-skeleton-loader>
                </template>
                <template v-else>
                  {{ detail.manage_stock ? "Yes" : "No" }}
                </template>
              </td>
            </tr>
            <tr v-if="false">
              <td class="font-size-18 font-weight-500 py-2" width="250">
                Track Batch
              </td>
              <td class="font-weight-600 font-size-18 py-2">
                <template v-if="dataLoading">
                  <v-skeleton-loader
                    class="custom-skeleton width-80"
                    type="text"
                  >
                  </v-skeleton-loader>
                </template>
                <template v-else>
                  {{ detail.track_batch ? "Yes" : "No" }}
                </template>
              </td>
            </tr>
            <tr v-if="false">
              <td class="font-size-18 font-weight-500 py-2" width="250">
                Tax Preference
              </td>
              <td class="font-weight-600 font-size-18 py-2">
                <template v-if="dataLoading">
                  <v-skeleton-loader
                    class="custom-skeleton width-80"
                    type="text"
                  >
                  </v-skeleton-loader>
                </template>
                <template v-else>
                  {{ detail.is_taxable ? "Taxable" : "Non-Taxable" }}
                </template>
              </td>
            </tr>
            <tr v-if="false && detail.is_taxable">
              <td class="font-size-18 font-weight-500 py-2" width="250">Tax</td>
              <td class="font-weight-600 font-size-18 py-2">
                <template v-if="dataLoading">
                  <v-skeleton-loader
                    class="custom-skeleton width-80"
                    type="text"
                  >
                  </v-skeleton-loader>
                </template>
                <template v-else>
                  <template v-if="detail.tax"
                    >{{ detail.tax.name }} [{{
                      detail.tax.percentage
                    }}%]</template
                  >
                  <template v-else><em class="text-muted">No Tax</em></template>
                </template>
              </td>
            </tr>
            <tr v-if="false">
              <td class="font-size-18 font-weight-500 py-2" width="250">
                Tax Exemption
              </td>
              <td class="font-weight-600 font-size-18 py-2">
                <template v-if="dataLoading">
                  <v-skeleton-loader
                    class="custom-skeleton width-80"
                    type="text"
                  >
                  </v-skeleton-loader>
                </template>
                <template v-else>
                  <template v-if="detail.tax_exemption"
                    >{{ detail.tax_exemption.name }} [{{
                      detail.tax_exemption.reason
                    }}]</template
                  >
                  <template v-else
                    ><em class="text-muted">No Tax Exemption</em></template
                  >
                </template>
              </td>
            </tr>
            <!-- <tr class="custom-border-top">
              <th colspan="2" class="font-size-18 py-2">Cost Information</th>
            </tr> -->
            <!-- <tr>
              <td class="font-size-18 font-weight-500 py-2" width="250">
                Cost Price
              </td>
              <td class="font-weight-600 font-size-18 py-2">
                <template v-if="dataLoading">
                  <v-skeleton-loader
                    class="custom-skeleton width-80"
                    type="text"
                  >
                  </v-skeleton-loader>
                </template>
                <template v-else>
                  {{
                    $accountingJS.formatMoney(detail.purchase_cost)
                  }}</template
                >
              </td>
            </tr> -->
            <!-- <tr>
              <td class="font-size-18 font-weight-500 py-2" width="250">
                Selling cost / Unit cost
              </td>
              <td class="font-weight-600 font-size-18 py-2">
                <template v-if="dataLoading">
                  <v-skeleton-loader
                    class="custom-skeleton width-80"
                    type="text"
                  >
                  </v-skeleton-loader>
                </template>
                <template v-else>
                  {{ $accountingJS.formatMoney(detail.selling_cost) }}</template
                >
              </td>
            </tr> -->
            <!-- <tr>
              <td class="font-size-18 font-weight-500 py-2" width="250">
                Project Price
              </td>
              <td class="font-weight-600 font-size-18 py-2">
                <template v-if="dataLoading">
                  <v-skeleton-loader
                    class="custom-skeleton width-80"
                    type="text"
                  >
                  </v-skeleton-loader>
                </template>
                <template v-else>
                  {{
                    $accountingJS.formatMoney(detail.project_price)
                  }}</template
                >
              </td>
            </tr> -->

            <!-- <tr>
              <td class="font-size-18 font-weight-500 py-2" width="250">
                Threshold Price
              </td>
              <td class="font-weight-600 font-size-18 py-2">
                <template v-if="dataLoading">
                  <v-skeleton-loader
                    class="custom-skeleton width-80"
                    type="text"
                  >
                  </v-skeleton-loader>
                </template>
                <template v-else>
                  {{
                    $accountingJS.formatMoney(detail.threshold_price)
                  }}</template
                >
              </td>
            </tr> -->

            <tr v-if="false">
              <td class="font-size-18 font-weight-500 py-2" width="250">
                Commission Rate (%)
              </td>
              <td class="font-weight-600 font-size-18 py-2">
                <template v-if="dataLoading">
                  <v-skeleton-loader
                    class="custom-skeleton width-80"
                    type="text"
                  >
                  </v-skeleton-loader>
                </template>
                <template v-else> {{ detail.commission_rate }}</template>
              </td>
            </tr>
          </table>
        </perfect-scrollbar>
      </v-col>
      <v-col
        cols="6"
        class="pb-0 gray-background pl-4"
        style="border-left: 1px solid lightgray !important"
      >
        <perfect-scrollbar
          ref="sidebarScrollbar"
          :options="{ suppressScrollX: true }"
          class="scroll"
          style="max-height: 89vh position: relative"
        >
          <table class="width-100">
            <template v-if="false">
              <tr v-if="detail.product_type == 'goods'">
                <th colspan="2" class="font-size-18 py-2">
                  Pricing Information
                </th>
              </tr>

              <tr v-if="detail.product_type == 'goods'">
                <td class="font-size-18 font-weight-500 py-2" width="200">
                  Selling Price/Unit Price
                </td>
                <td class="font-weight-600 font-size-18 py-2">
                  <span class="text--uppercase pr-2 font-weight-700">{{
                    formatMoney(detail.sale_price)
                  }}</span>
                </td>
              </tr>
              <tr v-if="detail.product_type == 'goods'">
                <td class="font-size-18 font-weight-500 py-2" width="200">
                  Discounted price
                </td>
                <td class="font-weight-600 font-size-18 py-2">
                  <span class="text--uppercase pr-2">{{
                    formatMoney(detail.discount_price)
                  }}</span>
                </td>
              </tr>
            </template>

            <tr v-if="detail.product_type == 'goods'">
              <th colspan="2" class="font-size-18 py-2">
                Quantity Information
              </th>
            </tr>
            <tr v-if="detail.product_type == 'goods'">
              <td class="font-size-18 font-weight-500 py-2" width="250">
                <template>
                  <span class="tooltip-border-dashed">Initial stock</span>
                </template>
              </td>
              <td class="font-weight-600 font-size-18 py-2">
                <template v-if="detail.initial_stock">
                  <span class="font-weight-700">
                    <v-chip outlined text-color="" label small color="blue">
                      {{ detail.initial_stock }}
                    </v-chip>
                  </span></template
                >
                <template v-else>
                  <span class="text-muted"> no stock available </span>
                </template>
              </td>
            </tr>
            <tr v-if="detail.product_type == 'goods'">
              <td class="font-size-18 font-weight-500 py-2" width="250">
                <v-tooltip top content-class="custom-top-tooltip">
                  <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on" class="tooltip-border-dashed"
                      >Available Quantity</span
                    >
                  </template>
                  <span
                    >The available quantity for sale at<br />the beginning of
                    the accounting period.</span
                  >
                </v-tooltip>
              </td>
              <td class="font-weight-600 font-size-18 py-2">
                <template v-if="detail.available_stock">
                  <span class="font-weight-700">
                    <v-chip outlined text-color="" label small color="blue">
                      {{ detail.available_stock }}
                    </v-chip>
                  </span></template
                >
                <template v-else>
                  <span class="text-muted"> no available stock </span>
                </template>
              </td>
            </tr>
            <tr v-if="detail.product_type == 'goods'">
              <template v-if="detail.unit">
                <td class="font-size-18 font-weight-500 py-2" width="250">
                  UOM
                </td>
                <td class="font-weight-600 font-size-18 py-2">
                  <span v-if="detail && detail.unit"> {{ detail.unit }}</span>
                  <em v-else class="text-muted"> no UOM</em>
                </td>
              </template>
            </tr>

            <br />
            <template v-if="pType == 'equipment'">
              <tr v-if="allowPurchaseinfo">
                <th colspan="2" class="font-size-18 py-2">Import Info</th>
              </tr>
              <tr class="line-height5" v-if="allowPurchaseinfo">
                <td class="font-size-18 font-weight-500 py-2">Arrival Date</td>
                <td class="font-weight-600 font-size-18 py-2">
                  <template
                    v-if="
                      detail &&
                      detail.import_arrival_date &&
                      detail.import_arrival_date != 'null'
                    "
                  >
                    <span class="text-capitalize">{{
                      detail.import_arrival_date
                    }}</span>
                  </template>
                  <template v-else>
                    <span class="text-muted"> no import arrival date</span>
                  </template>
                </td>
              </tr>
              <tr class="line-height5" v-if="allowPurchaseinfo">
                <td class="font-size-18 font-weight-500 py-2">Permit No.</td>
                <td class="font-weight-600 font-size-18 py-2">
                  <template
                    v-if="
                      detail &&
                      detail.import_permit_no &&
                      detail.import_permit_no != 'null'
                    "
                  >
                    <span class="text-capitalize">{{
                      detail.import_permit_no
                    }}</span>
                  </template>
                  <template v-else>
                    <span class="text-muted"> no permit no.</span>
                  </template>
                </td>
              </tr>
              <tr class="line-height5" v-if="allowPurchaseinfo">
                <td class="font-size-18 font-weight-500 py-2">BOL No.</td>
                <td class="font-weight-600 font-size-18 py-2">
                  <template
                    v-if="
                      detail &&
                      detail.import_bol_no &&
                      detail.import_bol_no != 'null'
                    "
                  >
                    <span class="text-capitalize">{{
                      detail.import_bol_no
                    }}</span>
                  </template>
                  <template v-else>
                    <span class="text-muted"> no nol no.</span>
                  </template>
                </td>
              </tr>
              <tr class="line-height5" v-if="allowPurchaseinfo">
                <td class="font-size-18 font-weight-500 py-2">DO No.</td>
                <td class="font-weight-600 font-size-18 py-2">
                  <template
                    v-if="detail && detail.do_no && detail.do_no != 'null'"
                  >
                    <span class="text-capitalize">{{ detail.do_no }}</span>
                  </template>
                  <template v-else
                    ><span class="text-muted"> no do no.</span>
                  </template>
                </td>
              </tr>
              <br v-if="allowPurchaseinfo" />
              <tr
                style="
                  border-bottom: 1px solid lightgray !important;
                  padding: 10px;
                "
                v-if="allowPurchaseinfo"
              ></tr>
              <br v-if="allowPurchaseinfo" />
              <tr>
                <th colspan="2" class="font-size-18 py-2">Extended Info</th>
              </tr>
              <tr class="line-height5">
                <td class="font-size-18 font-weight-500 py-2">Asset Type</td>
                <td class="font-weight-600 font-size-18 py-2">
                  <template
                    v-if="
                      detail && detail.asset_type && detail.asset_type != 'null'
                    "
                  >
                    <span class="text-capitalize">{{ detail.asset_type }}</span>
                  </template>
                  <template v-else
                    ><span class="text-muted"> no asset type </span>
                  </template>
                </td>
              </tr>
              <tr class="line-height5">
                <td class="font-size-18 font-weight-500 py-2">Owner Ref.</td>
                <td class="font-weight-600 font-size-18 py-2">
                  <template
                    v-if="
                      detail && detail.owner_ref && detail.owner_ref != 'null'
                    "
                  >
                    <span class="text-capitalize">{{ detail.owner_ref }}</span>
                  </template>
                  <template v-else>
                    <span class="text-muted"> no owner ref.</span>
                  </template>
                </td>
              </tr>
              <tr class="line-height5">
                <td class="font-size-18 font-weight-500 py-2">Year</td>
                <td class="font-weight-600 font-size-18 py-2">
                  <template
                    v-if="detail && detail.year && detail.year != 'null'"
                  >
                    <span class="text-capitalize">{{ detail.year }}</span>
                  </template>
                  <template v-else>
                    <span class="text-muted">no year</span></template
                  >
                </td>
              </tr>
              <tr class="line-height5">
                <td class="font-size-18 font-weight-500 py-2">
                  Platform Height
                </td>
                <td class="font-weight-600 font-size-18 py-2">
                  <template
                    v-if="
                      detail &&
                      detail.plateform_height &&
                      detail.plateform_height != 'null'
                    "
                  >
                    <span class="text-capitalize">{{
                      detail.plateform_height
                    }}</span>
                  </template>
                  <template v-else>
                    <span class="text-muted"
                      >no plateform height</span
                    ></template
                  >
                </td>
              </tr>
              <tr class="line-height5">
                <td class="font-size-18 font-weight-500 py-2">Engine Type</td>
                <td class="font-weight-600 font-size-18 py-2">
                  <template
                    v-if="
                      detail &&
                      detail.engine_type &&
                      detail.engine_type != 'null'
                    "
                  >
                    <span class="text-capitalize">{{
                      detail.engine_type
                    }}</span>
                  </template>
                  <template v-else>
                    <span class="text-muted">no engine type</span></template
                  >
                </td>
              </tr>
              <tr class="line-height5">
                <td class="font-size-18 font-weight-500 py-2">Capacity</td>
                <td class="font-weight-600 font-size-18 py-2">
                  <template
                    v-if="
                      detail && detail.capacity && detail.capacity != 'null'
                    "
                  >
                    <span class="text-capitalize">{{ detail.capacity }}</span>
                  </template>
                  <template v-else>
                    <span class="text-muted">no capacity</span></template
                  >
                </td>
              </tr>
              <tr class="line-height5">
                <td class="font-size-18 font-weight-500 py-2">Code</td>
                <td class="font-weight-600 font-size-18 py-2">
                  <template
                    v-if="detail && detail.code && detail.code != 'null'"
                  >
                    <span class="text-capitalize">{{ detail.code }}</span>
                  </template>
                  <template v-else>
                    <span class="text-muted">no code</span></template
                  >
                </td>
              </tr>
              <tr class="line-height5">
                <td class="font-size-18 font-weight-500 py-2">
                  Chassis Number
                </td>
                <td class="font-weight-600 font-size-18 py-2">
                  <template
                    v-if="
                      detail &&
                      detail.chassis_number &&
                      detail.chassis_number != 'null'
                    "
                  >
                    <span class="text-capitalize">{{
                      detail.chassis_number
                    }}</span>
                  </template>
                  <template v-else>
                    <span class="text-muted">no chassis number</span></template
                  >
                </td>
              </tr>
              <tr class="line-height5">
                <td class="font-size-18 font-weight-500 py-2">Engine Number</td>
                <td class="font-weight-600 font-size-18 py-2">
                  <template
                    v-if="
                      detail &&
                      detail.engine_number &&
                      detail.engine_number != 'null'
                    "
                  >
                    <span class="text-capitalize">{{
                      detail.engine_number
                    }}</span>
                  </template>
                  <template v-else>
                    <span class="text-muted">no engine number</span></template
                  >
                </td>
              </tr>
              <tr class="line-height5">
                <td class="font-size-18 font-weight-500 py-2">LTA Plate No.</td>
                <td class="font-weight-600 font-size-18 py-2">
                  <template
                    v-if="
                      detail &&
                      detail.lta_plate_no &&
                      detail.lta_plate_no != 'null'
                    "
                  >
                    <span class="text-capitalize">{{
                      detail.lta_plate_no
                    }}</span>
                  </template>
                  <template v-else>
                    <span class="text-muted">no lta plate no</span></template
                  >
                </td>
              </tr>
              <tr class="line-height5">
                <td class="font-size-18 font-weight-500 py-2">NEA Plate No.</td>
                <td class="font-weight-600 font-size-18 py-2">
                  <template
                    v-if="
                      detail &&
                      detail.nea_plate_no &&
                      detail.nea_plate_no != 'null'
                    "
                  >
                    <span class="text-capitalize">{{
                      detail.nea_plate_no
                    }}</span>
                  </template>
                  <template v-else>
                    <span class="text-muted">no nea plate no</span></template
                  >
                </td>
              </tr>
              <tr class="line-height5">
                <td class="font-size-18 font-weight-500 py-2">
                  Original Fork C/W
                </td>
                <td class="font-weight-600 font-size-18 py-2">
                  <template
                    v-if="
                      detail &&
                      detail.originial_fork &&
                      detail.originial_fork != 'null'
                    "
                  >
                    <span class="text-capitalize">{{
                      detail.originial_fork
                    }}</span>
                  </template>
                  <template v-else>
                    <span class="text-muted"
                      >no original fork c/w</span
                    ></template
                  >
                </td>
              </tr>
              <tr class="line-height5">
                <td class="font-size-18 font-weight-500 py-2">NOA</td>
                <td class="font-weight-600 font-size-18 py-2">
                  <template v-if="detail && detail.noa && detail.noa != 'null'">
                    <span class="text-capitalize">{{ detail.noa }}</span>
                  </template>
                  <template v-else>
                    <span class="text-muted">no NOA</span></template
                  >
                </td>
              </tr>
              <tr class="line-height5">
                <td class="font-size-18 font-weight-500 py-2">LP/LM Cert</td>
                <td class="font-weight-600 font-size-18 py-2">
                  <template
                    v-if="detail && detail.lp_cert && detail.lp_cert != 'null'"
                  >
                    <span class="text-capitalize">{{ detail.lp_cert }}</span>
                  </template>
                  <template v-else>
                    <span class="text-muted">no lp/lm cert.</span></template
                  >
                </td>
              </tr>
              <tr class="line-height5">
                <td class="font-size-18 font-weight-500 py-2">
                  LP Cert Inspection Date
                </td>
                <td class="font-weight-600 font-size-18 py-2">
                  <template
                    v-if="
                      detail &&
                      detail.equip_start_date &&
                      detail.equip_start_date != 'null'
                    "
                  >
                    <span class="text-capitalize">{{
                      formatDate(detail.equip_start_date)
                    }}</span>
                  </template>
                  <template v-else>
                    <span class="text-muted"
                      >no LP cert. inspection date</span
                    ></template
                  >
                </td>
              </tr>
              <tr class="line-height5">
                <td class="font-size-18 font-weight-500 py-2">
                  LP Cert Expiry Date
                </td>
                <td class="font-weight-600 font-size-18 py-2">
                  <template
                    v-if="
                      detail &&
                      detail.equip_end_date &&
                      detail.equip_end_date != 'null'
                    "
                  >
                    <span class="text-capitalize">{{
                      formatDate(detail.equip_end_date)
                    }}</span>
                  </template>
                  <template v-else>
                    <span class="text-muted"
                      >no LP cert expiry date</span
                    ></template
                  >
                </td>
              </tr>
              <tr class="line-height5">
                <td class="font-size-18 font-weight-500 py-2">
                  Machinery All Risk
                </td>
                <td class="font-weight-600 font-size-18 py-2">
                  <template
                    v-if="
                      detail &&
                      detail.machinery_all_risk &&
                      detail.machinery_all_risk != 'null'
                    "
                  >
                    <span class="text-capitalize">{{
                      detail.machinery_all_risk
                    }}</span>
                  </template>
                  <template v-else>
                    <span class="text-muted"
                      >no machinery all risk</span
                    ></template
                  >
                </td>
              </tr>
            </template>
            <!-- <tr>
              <th colspan="2" class="font-size-18 py-2">
                Warranty Information
              </th>
            </tr>
           

            <tr>
              <td
                class="font-size-18 font-weight-500 py-2"
                width="200"
                v-if="lodash.isEmpty(detail.warranty_data) === false"
              >
                Duration
              </td>
              <td
                class="font-weight-600 font-size-18 py-2"
                v-if="lodash.isEmpty(detail.warranty_data) === false"
              >
                <template v-for="(warranty, index) in detail.warranty_data">
                  <span
                    :key="'warranty-data-' + index"
                    class="text--uppercase pr-2"
                    >{{ warranty.value }} {{ warranty.field
                    }}<template v-if="warranty.value != 1">s</template></span
                  >
                </template>
              </td>
              <td v-else>
                <em class="text-muted"> not available</em>
              </td>
            </tr> 
            -->
            <tr v-if="false">
              <th colspan="2" class="font-size-18 py-2">
                Warranty Information
              </th>
            </tr>
            <tr v-if="false">
              <td class="font-size-18 font-weight-500 py-2" width="200">
                Duration
              </td>
              <td class="font-weight-600 font-size-18 py-2">
                <span v-if="detail.warranty_check && detail.duration_time"
                  >{{ detail.warranty_check }}
                  <span class="text-capitalize"
                    >{{ detail.duration_time }}
                    <template v-if="detail.warranty_check != 1">(s)</template>
                  </span>
                </span>
                <em v-else class="text-muted">no warranty</em>
              </td>
            </tr>

            <tr
              v-if="false"
              :class="{
                'custom-border-top':
                  lodash.isEmpty(detail.warranty_data) === false,
              }"
            >
              <th colspan="2" class="font-size-18 py-2">Accounting Stock</th>
            </tr>
            <tr v-if="false">
              <td class="font-size-18 font-weight-500 py-2" width="250">
                <v-tooltip top content-class="custom-top-tooltip">
                  <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on" class="tooltip-border-dashed"
                      >Re-Order Level</span
                    >
                  </template>
                  <span
                    >When the stock reaches the reorder level, <br />a
                    notification will be sent to you.</span
                  >
                </v-tooltip>
              </td>
              <td class="font-weight-600 font-size-18 py-2">
                <template v-if="dataLoading">
                  <v-skeleton-loader
                    class="custom-skeleton width-80"
                    type="text"
                  >
                  </v-skeleton-loader>
                </template>
                <template v-else>{{
                  $accountingJS.formatNumber(detail.reorder_level)
                }}</template>
              </td>
            </tr>

            <tr v-if="false">
              <td class="font-size-18 font-weight-500 py-2" width="250">
                <v-tooltip top content-class="custom-top-tooltip">
                  <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on" class="tooltip-border-dashed"
                      >Stock on Hand</span
                    >
                  </template>
                  <span>Current stock available for this item.</span>
                </v-tooltip>
              </td>
              <td class="font-weight-600 font-size-18 py-2">
                <template v-if="dataLoading">
                  <v-skeleton-loader
                    class="custom-skeleton width-80"
                    type="text"
                  >
                  </v-skeleton-loader>
                </template>
                <template v-else>{{
                  $accountingJS.formatNumber(detail.stock_on_hand)
                }}</template>
              </td>
            </tr>
            <tr v-if="false">
              <td class="font-size-18 font-weight-500 py-2" width="250">
                <v-tooltip top content-class="custom-top-tooltip">
                  <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on" class="tooltip-border-dashed"
                      >Committed Stock</span
                    >
                  </template>
                  <span
                    >Stock that is committed to job(s) but not yet
                    invoiced.</span
                  >
                </v-tooltip>
              </td>
              <td class="font-weight-600 font-size-18 py-2">
                <template v-if="dataLoading">
                  <v-skeleton-loader
                    class="custom-skeleton width-80"
                    type="text"
                  >
                  </v-skeleton-loader>
                </template>
                <template v-else>{{
                  $accountingJS.formatNumber(detail.committed_stock)
                }}</template>
              </td>
            </tr>
            <tr v-if="false">
              <td class="font-size-18 font-weight-500 py-2" width="250">
                <v-tooltip top content-class="custom-top-tooltip">
                  <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on" class="tooltip-border-dashed"
                      >Available for Sale</span
                    >
                  </template>
                  <span
                    >Available for sale = Stock on Hand - Committed Stock.</span
                  >
                </v-tooltip>
              </td>
              <td class="font-weight-600 font-size-18 py-2">
                <template v-if="dataLoading">
                  <v-skeleton-loader
                    class="custom-skeleton width-80"
                    type="text"
                  >
                  </v-skeleton-loader>
                </template>
                <template v-else>{{
                  $accountingJS.formatNumber(detail.available_stock)
                }}</template>
              </td>
            </tr>
          </table>

          <div
            v-if="false"
            class="mt-4"
            :class="{
              'custom-border-top':
                lodash.isEmpty(detail.warranty_data) === false,
            }"
          >
            <v-row>
              <v-col class="pt-0" md="8">
                <p class="font-size-18 font-weight-700 py-2 pl-1 my-0">
                  Product Summary
                </p>
              </v-col>
              <v-col class="pt-0" md="4">
                <v-select
                  :items="summaryFilter"
                  :disabled="dataLoading"
                  :loading="dataLoading"
                  v-model="summary"
                  hide-details
                  color="cyan"
                  item-color="cyan"
                  class="pt-0 mt-0 custom-appex-filter"
                  item-text="text"
                  item-value="value"
                  v-on:change="getSummary(true)"
                >
                </v-select>
              </v-col>
              <v-col md="6" offset-md="3" v-if="dataLoading">
                <v-progress-linear
                  color="cyan"
                  class="my-20"
                  indeterminate
                  rounded
                  height="6"
                ></v-progress-linear>
              </v-col>
              <v-col md="12" v-else class="text-center">
                <template v-if="series.length > 0">
                  <apexchart
                    type="bar"
                    :options="chartOptions"
                    :series="series"
                  ></apexchart>
                </template>
                <template v-else
                  ><em class="text--secondary font-size-16"
                    >Nothing Found...</em
                  ></template
                >
              </v-col>
            </v-row>
          </div>
        </perfect-scrollbar>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import moment from "moment-timezone";
//import { QUERY } from "@/core/services/store/request.module";
import CommonMixin from "@/core/plugins/common-mixin";
moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);

export default {
  mixins: [CommonMixin],
  data() {
    return {
      product: 0,
      dataLoading: true,
      summary: "this_week",
      summaryFilter: [
        { text: "This Week", value: "this_week" },
        { text: "Previous Week", value: "previous_week" },
        { text: "This Month", value: "this_month" },
        { text: "Previous Month", value: "previous_month" },
        /*{ text: "This Quarter", value: "this_quarter" },
        { text: "Previous Quarter", value: "previous_quarter" },*/
        { text: "This Year", value: "this_year" },
        { text: "Previous Year", value: "previous_year" },
      ],
      chartOptions: {
        chart: {
          id: "product-summary",
        },
        xaxis: {
          categories: [],
        },
        dataLabels: {
          enabled: true,
          style: {
            fontFamily: "'Poppins', Helvetica, Arial, sans-serif",
          },
        },
      },
      series: [],
      tOneFields: [
        {
          title: "Name",
          key: "name",
          child: false,
        },
        /*{
          title: "Reference",
          key: "reference",
          child: false
        },*/
        {
          title: "Item Code",
          key: "barcode",
          child: false,
        },
        /*{
          title: "SKU",
          key: "sku",
          child: false
        },
        {
          title: "UPC",
          key: "upc",
          child: false
        },
        {
          title: "EAN",
          key: "ean",
          child: false
        },
        {
          title: "ISBN",
          key: "isbn",
          child: false
        },*/
        {
          title: "Part Number",
          key: "part_number",
          child: false,
        },
        /*  {
          title: "Serial Number",
          key: "serial_number",
          child: false,
        }, */
        /*{
          title: "HSN Code",
          key: "hsn_code",
          child: false
        },*/
        {
          title: "Description",
          key: "description",
          child: false,
        },
        /*{
          title: "Supplier",
          key: "supplier",
          child: "display_name",
        },*/
        {
          title: "Brand",
          key: "brand",
          child: "text",
        },
        /* {
          title: "Manufacturer",
          key: "manufacturer",
          child: "text",
        }, */
        {
          title: "Category",
          key: "category",
          child: "text",
        },
        /* {
          title: "UOM",
          key: "uom",
          child: "text",
        }, */
      ],
    };
  },
  props: {
    allowPurchaseinfo: {
      type: Number,
      required: false,
      default: 0,
    },
    pType: {
      type: String,
      required: false,
    },
    detail: {
      type: Object,
      required: true,
      default: () => {
        return new Object();
      },
    },
  },
  watch: {
    detail: {
      deep: true,
      immediate: true,
      handler(param) {
        this.product = this.lodash.toSafeInteger(param.id);
        this.getSummary(false);
      },
    },
  },
  methods: {
    getSummary() {
      //scrollBottom
      // const _this = this;
      // if (_this.product > 0) {
      //   _this.dataLoading = true;
      //   _this.series = [];
      //   _this.$store
      //     .dispatch(QUERY, {
      //       url: "product/" + _this.product + "/summary",
      //       data: {
      //         filter: _this.summary,
      //       },
      //     })
      //     .then(({ data }) => {
      //       let categories = Object.values(data.categories);
      //       if (
      //         _this.lodash.isEmpty(categories) === false &&
      //         _this.lodash.isArray(categories)
      //       ) {
      //         _this.chartOptions = {
      //           ..._this.chartOptions,
      //           ...{
      //             xaxis: {
      //               categories: categories.map((row) => {
      //                 if (
      //                   _this.summary == "this_year" ||
      //                   _this.summary == "previous_year"
      //                 ) {
      //                   return moment(row).format("MMMM, YYYY");
      //                 }
      //                 return moment(row).format("Do, MMMM, YYYY");
      //               }),
      //             },
      //           },
      //         };
      //         if (_this.lodash.isEmpty(data.series) === false) {
      //           if (
      //             _this.lodash.isEmpty(data.series.quotation_counted) === false
      //           ) {
      //             _this.series.push({
      //               name: "Quotation",
      //               data: Object.values(data.series.quotation_counted),
      //             });
      //           }
      //           if (
      //             _this.lodash.isEmpty(data.series.invoice_counted) === false
      //           ) {
      //             _this.series.push({
      //               name: "Invoice",
      //               data: Object.values(data.series.invoice_counted),
      //             });
      //           }
      //           if (_this.lodash.isEmpty(data.series.job_counted) === false) {
      //             _this.series.push({
      //               name: "Job",
      //               data: Object.values(data.series.job_counted),
      //             });
      //           }
      //         }
      //       }
      //     })
      //     .catch((error) => {
      //       _this.logError(error);
      //     })
      //     .finally(() => {
      //       _this.dataLoading = false;
      //       if (_this.lodash.isEmpty(_this.series) === false && scrollBottom) {
      //         let objDiv = _this.$refs["sidebarScrollbar"].$el;
      //         _this.$nextTick(() => {
      //           objDiv.scrollTop = objDiv.scrollHeight;
      //         });
      //       }
      //     });
      // }
    },
  },
  mounted() {
    this.dataLoading = false;
    //this.getSummary();
  },
};
</script>
